import { Box, CircularProgress } from "@mui/material"
import React from "react"
import {
    DRAWER_WIDTH_LARGE_SCREEN,
    DRAWER_WIDTH_SMALL_SCREEN,
    TOP_BAR_HEIGHT_LARGE_SCREEN,
} from "layout/MainLayout/constants"
import { useResponsiveProperty } from "styles/theme/useResponsiveProperty"

export type MainContentAreaLoadingMaskProps = {
    withAdditionalOffset?: boolean
}

export const MainContentAreaLoadingMask = (props: MainContentAreaLoadingMaskProps): JSX.Element => {
    const responsiveProperty = useResponsiveProperty<CSSStyleDeclaration>()

    return (
        <Box
            className="main-content-area-loading-mask"
            sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                top: props.withAdditionalOffset ? TOP_BAR_HEIGHT_LARGE_SCREEN : 0,
                ...responsiveProperty({
                    property: "left",
                    largeScreenValue: DRAWER_WIDTH_LARGE_SCREEN,
                    smallScreenValue: DRAWER_WIDTH_SMALL_SCREEN,
                }),
            }}
        >
            <div className="screen-centered">
                <CircularProgress />
            </div>
        </Box>
    )
}

export default MainContentAreaLoadingMask
