import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ClassicUiEmbeddedMenuLeafDTO } from "generated/models"
import { Box } from "@mui/material"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"
import {
    DRAWER_WIDTH_LARGE_SCREEN,
    DRAWER_WIDTH_SMALL_SCREEN,
    TOP_BAR_HEIGHT_LARGE_SCREEN,
    TOP_BAR_HEIGHT_SMALL_SCREEN,
} from "layout/MainLayout/constants"
import { useResponsiveProperty } from "styles/theme/useResponsiveProperty"

export type ClassicUiContextProperties = {
    children?: ReactNode
    entries: ClassicUiEmbeddedMenuLeafDTO[]
}

export type ClassicUiContextProviderProperties = {
    setLoggedIn?: (loggedIn: boolean) => void
    href?: string
    children?: ReactNode
}
export const ClassicUiEmbeddingContext = React.createContext<ClassicUiContextProviderProperties>({})

/**
 * This component is used to embed the classic ui into the new ui.
 * It will only show the children if the current path is one of the entries.
 * But the element is always rendered, children will be only hidden unless appropriate route is active.
 *
 * @param entries
 * @param children
 * @constructor
 */
export const ClassicUiEmbeddingContextProvider = ({ entries, children }: ClassicUiContextProperties) => {
    const location = useLocation()

    const [show, setShow] = useState<boolean>(false)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [menuEntry, setMenuEntry] = useState<ClassicUiEmbeddedMenuLeafDTO>(undefined)

    useEffect(() => {
        triggerShow()

        if (loggedIn) {
            setMenuEntry(getEntry())
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, loggedIn])

    /**
     * This method will check if the current path is one of the entries.
     * If so, then show the children.
     */
    const triggerShow = () => {
        const show = getEntry() !== undefined
        setShow(show)
    }

    /**
     * This method will return the entry for the current path.
     */
    const getEntry = (): ClassicUiEmbeddedMenuLeafDTO | undefined => {
        return entries.find((entry) => location.pathname === entry.path)
    }

    const responsiveProperty = useResponsiveProperty<CSSStyleDeclaration>()
    const responsiveLeft = responsiveProperty({
        property: "left",
        largeScreenValue: DRAWER_WIDTH_LARGE_SCREEN,
        smallScreenValue: DRAWER_WIDTH_SMALL_SCREEN,
    })

    return (
        <ClassicUiEmbeddingContext.Provider
            value={{
                setLoggedIn: (loggedIn: boolean) => {
                    setLoggedIn(loggedIn)
                },
                href: menuEntry?.href,
            }}
        >
            <Box
                className={"classic-ui-embedding-context-provider"}
                sx={{
                    display: loggedIn && show ? "block" : "none",
                    position: "absolute",
                    ...responsiveProperty({
                        property: "top",
                        largeScreenValue: TOP_BAR_HEIGHT_LARGE_SCREEN,
                        smallScreenValue: TOP_BAR_HEIGHT_SMALL_SCREEN,
                    }),
                    right: 0,
                    bottom: 0,
                    overflow: "hidden",
                    ...responsiveLeft,
                }}
            >
                {children}
            </Box>
            {!loggedIn && show && <MainContentAreaLoadingMask withAdditionalOffset={true} />}
        </ClassicUiEmbeddingContext.Provider>
    )
}

export const useClassicUiContext = (): ClassicUiContextProviderProperties => {
    const context = useContext(ClassicUiEmbeddingContext)
    if (!context || Object.keys(context).length === 0) {
        throw new Error("Missing ClassicUiContext in its parent.")
    }

    return context
}
